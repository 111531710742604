import Modal from 'react-modal';
import React, { useState, useContext, useRef, useEffect } from "react";

import { AboutHouseTab } from "./AboutHouseTab";
import { AboutApplicantTab } from "./AboutApplicantTab";
import { MainContext } from "../../helpers/MainContext";
import LoadingIcon from "../forms/LoadingIcon";
import ExitModalButton from "../forms/ExitModalButton";
import { fetchWithTimeout } from "../../helpers/littleHelpers";

const initialFormValues = {
    minBedrooms: undefined,
    maxBerdooms: undefined,
    minHouseArea: undefined,
    maxHouseArea: undefined,
    minSiteArea: undefined,
    maxSiteArea: undefined,
    minPrice: undefined,
    maxPrice: undefined,
    withPoolOnly: undefined,
    elseImportant: "",
    name: "",
    contactInfo: "",
    secretOfInterestID: "",
    secretOfInterestName: "",
};
  
const FormsModal = () => {
    const {mainFormVisibility, setMainFormVisibility, secretOfInterest} = useContext(MainContext);
    const [currentTab, setCurrentTab] = useState('aboutHouse');
    const [values, setValues] = useState(initialFormValues);
    const [subStatus, setSubStatus] = useState("idle"); // "loading" / "sent" / "error"
    const [errorMessage, setErrorMessage] = useState("");
    const modalHeight = useRef(0);

    useEffect(() => {
        setValues(oldValues => ({...oldValues, secretOfInterestID: secretOfInterest.objectID,
            secretOfInterestName: secretOfInterest.name
        }))
    }, [secretOfInterest])

    const tabList = [
        {
          name: 'aboutHouse',
          content: (
            <AboutHouseTab
                key="AboutHouseTab"
                setValues={setValues}
                values={values}
                changeTab={setCurrentTab}
                />
          )
        },
        {
          name: 'aboutApplicant',
          content: (
            <AboutApplicantTab 
                key="AboutApplicant"
                setValues={setValues}
                values={values}
                changeTab={setCurrentTab}
            />
          )
        },
    ]
    
    const closeModal = () => {
        if (typeof(document.getElementsByClassName("imageGallery")[0]) != 'undefined' && document.getElementsByClassName("imageGallery")[0] != null) {
            document.getElementsByClassName("imageGallery")[0].style.visibility = "visible";
        }
        setMainFormVisibility(false)
        setSubStatus("idle");
    }

    const saveFormData = async () => {
        const response = await fetchWithTimeout('https://system.landshaft.life/webhook/5cb1b991-7df3-4946-8863-69dfe99fb0ab', {
            timeout: 5000,
            method: 'POST',
            body: JSON.stringify(values)
        });

        if (response.status !== 200) {
            setSubStatus("error");
            throw new Error(`Ошибка соединения: ${response.status}`); 
        } else {
            setSubStatus("sent");
        }

      }
    
    const onSubmit = async (e) => {
        e.preventDefault();
        modalHeight.current = document.getElementsByClassName("Modal")[0].offsetHeight;
        setSubStatus("loading");

        try {
            console.log("!!!! \nattempting to send form data");
            await saveFormData();      
            setValues(initialFormValues);
        } catch (e) {
            setSubStatus("error");
            setErrorMessage(`Отправка не произведена! ${e.message}`);
        }
    }
    
    Modal.setAppElement(`#___gatsby`);
    
    return (
        <Modal 
            isOpen={mainFormVisibility}
            onRequestClose={closeModal}
            className="Modal"
            overlayClassName="Overlay"
        >
            <ExitModalButton closeFunction={closeModal} ariaLabel="Закрыть запись на просмотр домов в продаже" />
            { subStatus === "idle" &&
                <form onSubmit={onSubmit} className={`formBody ${currentTab}`} >
                    {
                        tabList.map((tab, i) => {
                            if(tab.name === currentTab) {
                            return (
                                <div key={tab.name} className="tabContent">
                                    {tab.content}
                                </div>
                            );
                            } else {
                            return null;
                            }
                        })
                    }
                </form>
            }
            { subStatus === "loading" &&
                <div className="loadingContainer" style={{height: `${modalHeight.current}px`}}>
                    <LoadingIcon />
                </div>
            }
            { subStatus === "sent" &&
                <div className="successForm" style={{height: `${modalHeight.current}px`}}>
                    <p>Заявка успешно отправлена</p>
                </div> 
            }
            { subStatus === "error" &&
                <>  
                    <div className="errorMessage" style={{height: `${modalHeight.current}px`}}>
                        <p>Что-то пошло не так... <br/>Пожалуйста, попробуйте позднее или свяжитесь с нами по телефону +7 (985) 232-87-78</p>
                        <p><code>{errorMessage}</code></p>
                    </div>
                </>
            }
        </Modal>
    );
}

export default FormsModal