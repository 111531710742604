import React, {useEffect, useRef} from "react"

import { 
    SliderBedrooms,
    SliderHouseArea,
    SliderSiteArea,
    SliderBudget
} from "./nouislider"

export const AboutHouseTab = (props) => {
    const pool = useRef()

    useEffect(() => {
        if (props.values.withPoolOnly === true) {
            pool.current.checked = true
        }
    }, [props.values.withPoolOnly])

    const onChangeBedrooms = (data) => {
        props.setValues(oldValues => ({...oldValues, minBedrooms: data[0], maxBerdooms: data[1]}))
    }
    const onChangeHouseArea = (data) => {
        props.setValues(oldValues => ({...oldValues, minHouseArea: data[0], maxHouseArea: data[1]}))
    }
    const onChangeSiteArea = (data) => {
        props.setValues(oldValues => ({...oldValues, minSiteArea: data[0], maxSiteArea: data[1]}))
    }
    const onChangeBudget = (data) => {
        props.setValues(oldValues => ({...oldValues, minPrice: data[0], maxPrice: data[1]}))
    }

    const onChangeWithPoolOnly = () => {
        props.setValues(oldValues => ({...oldValues, withPoolOnly: !props.values.withPoolOnly}))
    }

    return (
        <>
        <h2>Какой дом вы ищите?</h2>
        <label htmlFor="sliderHouseArea">площадь дома</label>
        <SliderHouseArea key="SliderHouseArea" min={300} max={5000} currentMin={props.values.minHouseArea} currentMax={props.values.maxHouseArea} onChange={onChangeHouseArea} />
        <label htmlFor="sliderSiteArea">площадь участка</label>
        <SliderSiteArea key="SliderSiteArea" min={10} max={300} currentMin={props.values.minSiteArea} currentMax={props.values.maxSiteArea} onChange={onChangeSiteArea} />
        <label htmlFor="sliderBedrooms">количество спален</label>
        <SliderBedrooms key="SliderBedrooms" min={1} max={10} currentMin={props.values.minBedrooms} currentMax={props.values.maxBerdooms} onChange={onChangeBedrooms}/>
        <label htmlFor="sliderBudget">бюджет</label>
        <SliderBudget key="SliderBudget" min={1000000} max={99000000} currentMin={props.values.minPrice} currentMax={props.values.maxPrice} onChange={onChangeBudget} />
        <div id="poolOptionBox">
          <label htmlFor="withPoolOnly">бассейн</label>
        <input ref={pool} type="checkbox" className="switch" id="withPoolOnly" onChange={onChangeWithPoolOnly} />
        </div>
        <button 
            onClick={() => props.changeTab("aboutApplicant")} 
            className="button nextTabButton">
            Далее
        </button>
        </>
    )
}

