import Modal from 'react-modal';
import React, {useState, useContext, useRef, useEffect} from "react";

import { MainContext } from "../../helpers/MainContext";
import LoadingIcon from "./LoadingIcon";
import ExitModalButton from "./ExitModalButton";
import { fetchWithTimeout } from "../../helpers/littleHelpers";


const ObjectsRentModal = ( { idOfInterest, name } ) => {
    const {objectRentFormVisibility, setObjectRentFormVisibility, rentObjectOfInterest} = useContext(MainContext);
    let pathname = "/";
    
    if (typeof window !== "undefined" && window?.location?.pathname) {
        pathname = window.location.pathname;
    }
    
    const initialFormValues = {
        formType: "rentInform",
        objectOfInterestID: idOfInterest,
        mansionName: name,
        pathname,
        name: "",
        contactInfo: "",
        elseImportant: "",
    };
  
    const [values, setValues] = useState(initialFormValues);
    const [subStatus, setSubStatus] = useState("idle"); // "loading" / "sent" / "error"
    const [errorMessage, setErrorMessage] = useState("");
    const modalHeight = useRef(0);

    useEffect(() => {
        if(idOfInterest === undefined || name === undefined) {
          
          setValues({
                formType: "rentInform",
                objectOfInterestID: rentObjectOfInterest.objectID,
                pathname,
                mansionName: rentObjectOfInterest.name,
                name: "",
                contactInfo: "",
                elseImportant: "",
            })
        }
    }, [rentObjectOfInterest.objectID, rentObjectOfInterest.name])

    const set = name => {
        return ({ target: { value } }) => {
            setValues(oldValues => ({ ...oldValues, [name]: value }))
        }
    }

    const closeModal = () => {
        document.getElementsByClassName("imageGallery")[0].style.visibility = "visible"; 
        setObjectRentFormVisibility(false)
        setSubStatus("idle");
    }

    const saveFormData = async () => {
        const response = await fetchWithTimeout('https://system.landshaft.life/webhook/5cb1sb99d-7df3-4946-8s63-69dfe9asd0ab', {
            timeout: 5000,
            method: 'POST',
            body: JSON.stringify(values)
        });
        
        if (response.status !== 200) {
            setSubStatus("error");
            throw new Error(`Ошибка соединения: ${response.status}`); 
        } else {
            setSubStatus("sent");
        }
    }
    
    const onSubmit = async (e) => {
        e.preventDefault();
        modalHeight.current = document.getElementsByClassName("Modal")[0].offsetHeight;
        setSubStatus("loading");

        try {
            await saveFormData();      
            setValues(initialFormValues);
        } catch (e) {
            setSubStatus("error");
            setErrorMessage(`Отправка не произведена! ${e.message}`);
        }

    }
    
    Modal.setAppElement(`#___gatsby`);

    return (
        <Modal 
        isOpen={objectRentFormVisibility}
        onRequestClose={closeModal}
        className="Modal rentInform"
        overlayClassName="Overlay"
        >   
            <ExitModalButton closeFunction={closeModal} ariaLabel="Закрыть окно запроса возможности аренды" />
            { subStatus === "idle" &&
            <form onSubmit={onSubmit} className="formBody" >
                    <div className="objectFormContent">
                        <label htmlFor="name">Как к вам обращаться?</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            required
                            minLength="2"
                            size="20"
                            onChange={set("name")}
                            value={values.name}
                        ></input>
                        <label htmlFor="contactInfo">Как свяжемся?</label>
                        <textarea
                            id="contactInfo"
                            name="contactInfo"
                            required
                            placeholder="Удобное время и способ связи"
                            onChange={set("contactInfo")}
                            value={values.contactInfo}
                        ></textarea>
                        <label id="elseImportantLabel" htmlFor="elseImportant">
                            Что ещё важно для вас?
                        </label>
                        <textarea
                            id="elseImportant"
                            name="elseImportant"
                            onChange={set("elseImportant")}
                            value={values.elseImportant}
                        ></textarea>
                        <button type="submit" className="button submit">
                            Отправить запрос
                        </button>
                    </div>
                </form>
            }
            { subStatus === "loading" &&
                <div className="loadingContainer" style={{height: `${modalHeight.current}px`}}>
                    <LoadingIcon />
                </div>
            }
            { subStatus === "sent" &&
                <div className="successForm" style={{height: `${modalHeight.current}px`}}>
                    <p>
                        Запрос успешно отправлен
                    </p>
                </div>
            }
            { subStatus === "error" &&
                <>  
                    <div className="errorMessage" style={{height: `${modalHeight.current}px`}}>
                        <p>Что-то пошло не так... <br/>Пожалуйста, попробуйте позднее или свяжитесь с нами по телефону +7 (985) 232-87-78</p>
                        <p><code>{errorMessage}</code></p>
                    </div>
                </>
            }
        </Modal>
    )
}

export default ObjectsRentModal;