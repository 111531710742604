import React, {useEffect, useRef} from "react";
import Nouislider from "nouislider-react";
import 'nouislider/dist/nouislider.css';

import {areaMeasureNaming, spacefyNumber} from "../../helpers/littleHelpers"

/* 
docs on original js labrary https://refreshless.com/nouislider/
docs on plugin used in these components - https://mmarkelov.github.io/react-nouislider/
*/

function formatNumber (value, type="none") {
    let text;
    let localNumber = spacefyNumber(value, 0);

    if ( type === "siteArea" ) {
        let postfix = areaMeasureNaming(localNumber);
        text = `${localNumber} ${postfix}`;
    } if ( type === "houseArea" ) {
        text = `${localNumber} м²`;
    } if ( type === "budget" ) {
        text = `$${localNumber}`;
    } if ( type === "none" ) {
        text = localNumber;
    }
    return text;
}

const activeSliderColor = "rgba(31, 38, 135, 0.7)" // rgba(31, 38, 135, 0.7) // #002554 // #578270


export const SliderHouseArea = ( {min=350, max=3750, currentMin, currentMax, onChange} ) => {
    const isTouched = useRef(false);
    const sliderId = "sliderHouseArea";
    const startPositions = [
        currentMin === undefined ? (min + (max * 0.2)) : currentMin,
        currentMax === undefined ? (max - (max * 0.2)) : currentMax
    ]
    
    useEffect(() => {
        if (currentMin !== undefined || currentMax !== undefined) {
            document.getElementsByClassName("noUi-connect")[0].style.background = activeSliderColor;
            isTouched.current = true;
        }
    },[currentMin, currentMax])

    function treatHouseAreaTouched () {
        const sliderObject = document.getElementById("sliderHouseArea");
        if (isTouched.current === false) {
            sliderObject.getElementsByClassName("noUi-connect")[0].style.background = activeSliderColor;
            isTouched.current = true;
        }
    }

    function showTooltip (values, handle) {
        const sliderObject = document.getElementById(sliderId);
        const handleClassList = sliderObject.getElementsByClassName("noUi-handle")[handle].classList;
        handleClassList.add("noUi-active")
        setTimeout(
            () => handleClassList.remove("noUi-active")
        , 1300)
    }

    return (
        <Nouislider 
            id={sliderId}
            range={{ min: min, max: max }}
            step={100}
            margin={500}
            pips={{ 
                mode: 'positions',
                values: [0, 25, 50, 75, 100],
                density: 4,
                stepped: true
                }}
            animate={false}
            tooltips={ 
                [{to: function ( value ) {
                    let newV = formatNumber(value, "houseArea");
                    return newV
                    }, 
                from: Number },
                { 
                to: function ( value ) {
                    let newV = formatNumber(value, "houseArea");
                    return newV 
                    }, 
                from: Number }]
                }
            start={startPositions} 
            onSet={onChange}
            onStart={treatHouseAreaTouched}
            onSlide={treatHouseAreaTouched}
            onChange={showTooltip}
            connect
        />
    );
}

export const SliderSiteArea = ( {min=12, max=280, currentMin, currentMax, onChange} ) => {
    const isTouched = useRef(false);
    const sliderId ="sliderSiteArea";
    const startPositions = [
        currentMin === undefined ? (min + (max * 0.1)) : currentMin,
        currentMax === undefined ? (max * 0.5) : currentMax
    ]

    useEffect(() => {
        if (currentMin !== undefined || currentMax !== undefined) {
            document.getElementsByClassName("noUi-connect")[1].style.background = activeSliderColor;
            isTouched.current = true;
        }
    }, [currentMin, currentMax])

    function treatTouched () {
        const sliderObject = document.getElementById(sliderId);
        if (isTouched.current === false) {
            let connector = sliderObject.getElementsByClassName("noUi-connect")[0];
            connector.style.background = activeSliderColor;
            isTouched.current = true;
        }
    }

    function showTooltip (values, handle) {
        const sliderObject = document.getElementById(sliderId);
        const handleClassList = sliderObject.getElementsByClassName("noUi-handle")[handle].classList;
        handleClassList.add("noUi-active")
        setTimeout(
            () => handleClassList.remove("noUi-active")
        , 1300)
    }

    return (
        <Nouislider 
            id={sliderId}
            range={{ min: min, max: max }} 
            step={5}
            margin={30}
            pips={{ 
                mode: 'positions',
                values: [0, 25, 50, 75, 100],
                density: 4,
                stepped: true
                }}
            animate={false}
            tooltips={ 
                [{to: function ( value ) {
                    let newV = formatNumber(value, "siteArea");
                    return newV
                    }, 
                from: Number },
                { 
                to: function ( value ) {
                    let newV = formatNumber(value, "siteArea");
                    return newV 
                    }, 
                from: Number }]
                }
            start={startPositions}
            onSet={onChange} 
            // onStart={treatTouched}
            onSlide={treatTouched}
            onChange={showTooltip}
            connect
        />
    );
}

export const SliderBedrooms = ( {min=1, max=10, currentMin, currentMax, onChange} ) => {
    const isTouched = useRef(false);
    const sliderId = "sliderBedrooms";
    const startPositions = [
        currentMin === undefined ? 3 : currentMin,
        currentMax === undefined ? 7 : currentMax
    ]

    useEffect(() => {
        if (currentMin !== undefined || currentMax !== undefined) {
            document.getElementsByClassName("noUi-connect")[2].style.background = activeSliderColor;
            isTouched.current = true;
        }
    }, [currentMin, currentMax])

    function filterPips(value, type) {
        /*
        https://refreshless.com/nouislider/pips/
        -1 (no pip at all)
        0 (no value)
        1 (large value)
        2 (small value)
        */

        if (type === 0) {
            return value = 1 ? 1 : 0;
        }
        return value === 1 || value === 10 ? 1 : 2;
    }

    function treatTouched () {
        if (isTouched.current === false) {
            document.getElementById(sliderId).getElementsByClassName("noUi-connect")[0].style.background = activeSliderColor;
            isTouched.current = true;
        }
    }
    
    function showTooltip (values, handle) {
        const sliderObject = document.getElementById(sliderId);
        const handleClassList = sliderObject.getElementsByClassName("noUi-handle")[handle].classList;
        handleClassList.add("noUi-active")
        setTimeout(
            () => handleClassList.remove("noUi-active")
        , 1300)
    }

    return (
        <Nouislider 
            id={sliderId}
            range={{ min: min, max: max }} 
            step={1}
            margin={1}
            pips={{
                mode: 'steps',
                density: 10,
                filter: filterPips,
                format: {to: function ( value ) {
                    let newV = formatNumber(value);
                    return newV; 
                    }, 
                from: Number }
            }}
            tooltips={ 
                [{to: function ( value ) {
                    let newV = formatNumber(value);
                    return newV; 
                    }, 
                from: Number },
                { 
                to: function ( value ) {
                    let newV = formatNumber(value);
                    return newV; 
                    }, 
                from: Number }]
                }
            animate={false}
            start={startPositions} 
            onSet={onChange}
            onStart={treatTouched}
            onSlide={treatTouched}
            onChange={showTooltip}
            connect
        />
    );
    
}

export const SliderBudget = ( {min=1000000, max=100000000, currentMin, currentMax, onChange} ) => {
    const isTouched = useRef(false);
    const sliderId = "sliderBudget";
    const startPositions = [
        // currentMin === undefined ? (min + (max * 0.1)) : currentMin,
        // currentMax === undefined ? (max * 0.5) : currentMax
        6000000,
        26000000
    ]

    useEffect(() => {
        if (currentMin !== undefined || currentMax !== undefined) {
            document.getElementsByClassName("noUi-connect")[3].style.background = activeSliderColor;
            isTouched.current = true;
        }
    }, [currentMin, currentMax])

    function treatTouched () {
        const sliderObject = document.getElementById(sliderId);
        if (isTouched.current === false) {
            sliderObject.getElementsByClassName("noUi-connect")[0].style.background = activeSliderColor;
            isTouched.current = true;
        }
    }
    
    function showTooltip (values, handle) {
        const sliderObject = document.getElementById(sliderId);
        const handleClassList = sliderObject.getElementsByClassName("noUi-handle")[handle].classList;
        handleClassList.add("noUi-active")
        setTimeout(
            () => handleClassList.remove("noUi-active")
        , 1300)
    }

    return (
        <Nouislider 
            id={sliderId}
            range={{
                min: [min, 100000],
                '20%': [4000000, 100000],
                '50%': [12000000, 1000000],
                max: [max]
            }}
            margin={1000000}
            keyboardDefaultStep={1000000}
            pips={{ 
                mode: 'values',
                values: [4000000, 64000000],
                stepped: true,
                density: 4,
                format: {to: function ( value ) {
                    let newV = formatNumber(value, "budget");
                    return newV
                    }, 
                from: Number }
                }}
            animate={false}
            tooltips={ 
                [{to: function ( value ) {
                    let newV = formatNumber(value, "budget");
                    return newV
                    }, 
                from: Number },
                { 
                to: function ( value ) {
                    let newV = formatNumber(value, "budget");
                    return newV 
                    }, 
                from: Number }]
                }
            start={startPositions}
            onSet={onChange}
            onStart={treatTouched}
            onSlide={treatTouched}
            onChange={showTooltip}
            connect
        />
    );
}
