import React, {useRef, useEffect} from "react"
import SanityImage from "gatsby-plugin-sanity-image" 

const Image = ({ image,
    index, 
    imagesCount, 
    targetWidth = 1400, 
    styleWidth = "100%" }) => {

    const sanityImageWidthSetting = useRef(targetWidth); // 2560 - max

    useEffect(() => {
        if (window.innerWidth > sanityImageWidthSetting.current * 2) {
            sanityImageWidthSetting.current = window.innerWidth / 2;
        }
    }, [])

    return <>
        <SanityImage
            {...image}
            alt=""
            key={`${image._key}-main-image`}
            id={`${image._key}-main-image`}
            width={sanityImageWidthSetting.current}
            style={{
                '--aspect-ratio': `${image.asset.metadata.dimensions.aspectRatio}`,
                width: styleWidth,
            }}
            loading={index === 0 || index === imagesCount - 1 || index === 1 ? "eager" : "lazy"}
            config={{
                quality: 85,
                fit: "min",
                auto: "format",
                crop: "entropy",
            }}
        ></SanityImage>
    </>
}

const isValid = (prevImage, nextImage) => {
    return prevImage._key === nextImage._key;
}

export const ImageMemoized = React.memo(Image, isValid);
