import React from "react"

export const AboutApplicantTab = props => {
    
  const set = name => {
    return ({ target: { value } }) => {
      props.setValues(oldValues => ({ ...oldValues, [name]: value }))
    }
  }

  return (
    <>
      <label htmlFor="elseImportant">
        Что ещё важно для вас?
      </label>
      <textarea
        id="elseImportant"
        name="elseImportant"
        onChange={set("elseImportant")}
        value={props.values.elseImportant}
      ></textarea>
      <label htmlFor="name">Как к вам обращаться?</label>
      <input
        type="text"
        id="name"
        name="name"
        required
        minLength="2"
        size="20"
        onChange={set("name")}
        value={props.values.name}
      ></input>
      <label htmlFor="contactInfo">Как свяжемся?</label>
      <textarea
        id="contactInfo"
        name="contactInfo"
        required
        placeholder="Удобное время и способ связи"
        onChange={set("contactInfo")}
        value={props.values.contactInfo}
      ></textarea>
      <button
        onClick={() => props.changeTab("aboutHouse")}
        className="button previousTabButton"
      >
        ← <span>Назад</span>
      </button>
      <button type="submit" className="button submit">
        Отправить заявку
      </button>
    </>
  )
}
