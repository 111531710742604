import React from "react"

export const GoFullscreenButton = ({ passClassName, onClickFunction, ariaLabel }) => {

    return (
        <button className={passClassName} onClick={onClickFunction} aria-label={ariaLabel} >
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" >
                <path fillRule="evenodd" clipRule="evenodd" fill="#578270" d="M8.2,24.2l5.1-5.1c0.7-0.7,1.9-0.7,2.6,0c0.7,0.7,0.7,1.9,0,2.6l-5.1,5.1c0.6,0.1,1.2,0.3,1.8,0.5
                    c0.5,0.2,0.8,0.5,1,1c0.2,0.4,0.2,1,0.1,1.4c-0.2,0.5-0.5,0.8-1,1c-0.9,0.4-1.6-0.1-2.5-0.2c-0.4-0.1-0.8-0.1-1.2-0.1
                    c-1.4,0-2.3,0.4-3.6,0.8l-0.7,0.3C4,31.7,3.3,31,3.6,30.3l0.3-0.7c0.5-1.2,0.8-2.2,0.8-3.6c0-0.8-0.1-1.6-0.4-2.3
                    c-0.2-0.5-0.2-1,0.1-1.4c0.2-0.4,0.6-0.8,1-1c0.5-0.2,1-0.2,1.4,0.1c0.4,0.2,0.8,0.6,1,1C7.9,23,8.1,23.6,8.2,24.2L8.2,24.2z
                    M10.8,8.2l5.1,5.1c0.7,0.7,0.7,1.9,0,2.6c-0.7,0.7-1.9,0.7-2.6,0l-5.1-5.1c-0.1,0.6-0.3,1.2-0.5,1.8c-0.2,0.5-0.5,0.8-1,1
                    c-0.4,0.2-1,0.2-1.4,0.1c-0.5-0.2-0.8-0.5-1-1c-0.2-0.4-0.2-1-0.1-1.4C4.5,10.6,4.6,9.7,4.6,9c0-1.4-0.4-2.3-0.8-3.6L3.6,4.7
                    C3.3,4,4,3.3,4.7,3.6l0.7,0.3C6.6,4.3,7.6,4.7,9,4.6c0.8,0,1.6-0.1,2.3-0.4c0.5-0.2,1-0.2,1.4,0.1c0.4,0.2,0.8,0.6,1,1
                    c0.2,0.5,0.2,1-0.1,1.4c-0.2,0.4-0.6,0.8-1,1C12,7.9,11.4,8.1,10.8,8.2L10.8,8.2z M26.8,10.8l-5.1,5.1c-0.7,0.7-1.9,0.7-2.6,0
                    c-0.7-0.7-0.7-1.9,0-2.6l5.1-5.1c-0.6-0.1-1.2-0.3-1.8-0.5c-0.5-0.2-0.8-0.5-1-1c-0.2-0.4-0.2-1-0.1-1.4c0.2-0.5,0.5-0.8,1-1
                    c0.4-0.2,1-0.2,1.4-0.1c0.7,0.3,1.5,0.4,2.3,0.4c1.4,0,2.3-0.4,3.6-0.8l0.7-0.3C31,3.3,31.7,4,31.4,4.7l-0.3,0.7
                    c-0.5,1.2-0.8,2.2-0.8,3.6c0,0.8,0.1,1.6,0.4,2.3c0.2,0.5,0.2,1-0.1,1.4c-0.2,0.4-0.6,0.8-1,1c-0.5,0.2-1,0.2-1.4-0.1
                    c-0.4-0.2-0.8-0.6-1-1C27.1,12,26.9,11.4,26.8,10.8L26.8,10.8z M24.2,26.8l-5.1-5.1c-0.7-0.7-0.7-1.9,0-2.6c0.7-0.7,1.9-0.7,2.6,0
                    l5.1,5.1c0.1-0.6,0.3-1.2,0.5-1.8c0.2-0.5,0.5-0.8,1-1c0.4-0.2,1-0.2,1.4-0.1c0.5,0.2,0.8,0.5,1,1c0.2,0.4,0.2,1,0.1,1.4
                    c-0.1,0.3-0.2,0.7-0.3,1.1c-0.1,0.4-0.1,0.8-0.1,1.2c0,1.4,0.4,2.3,0.8,3.6l0.3,0.7c0.2,0.7-0.4,1.3-1.1,1.1l-0.7-0.3
                    c-1.2-0.5-2.2-0.8-3.6-0.8c-0.4,0-0.8,0-1.2,0.1c-0.4,0.1-0.7,0.1-1.1,0.3c-0.5,0.2-1,0.2-1.4-0.1c-0.4-0.2-0.8-0.6-1-1
                    c-0.2-0.5-0.2-1,0.1-1.4c0.2-0.4,0.6-0.8,1-1C23,27.1,23.6,26.9,24.2,26.8z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#FBFBFB" d="M4.4,30.6C5,29,5.5,27.8,5.5,26c0-0.9-0.2-1.8-0.4-2.6c-0.2-0.5,0.1-1.1,0.6-1.3c0.5-0.2,1.1,0.1,1.3,0.6
                    c0.4,1.1,0.6,2.2,0.6,3.4l6.4-6.4c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4l-6.4,6.4c1.1,0,2.3,0.2,3.4,0.6c0.5,0.2,0.8,0.8,0.6,1.3
                    c-0.2,0.5-0.8,0.8-1.3,0.6c-0.8-0.3-1.7-0.4-2.6-0.4C7.2,29.5,6,30,4.4,30.6L4.4,30.6z M23.4,29.9c-0.5,0.2-1.1-0.1-1.3-0.6
                    c-0.2-0.5,0.1-1.1,0.6-1.3c1.1-0.4,2.2-0.6,3.4-0.6l-6.4-6.4c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l6.4,6.4
                    c0-1.1,0.2-2.3,0.6-3.4c0.2-0.5,0.8-0.8,1.3-0.6c0.5,0.2,0.8,0.8,0.6,1.3c-0.3,0.8-0.4,1.7-0.4,2.6c0,1.8,0.5,3,1.1,4.6
                    C29,30,27.8,29.5,26,29.5C25.1,29.5,24.2,29.7,23.4,29.9L23.4,29.9z M29.9,11.6c0.2,0.5-0.1,1.1-0.6,1.3c-0.5,0.2-1.1-0.1-1.3-0.6
                    c-0.4-1.1-0.6-2.2-0.6-3.4l-6.4,6.4c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4l6.4-6.4c-1.1,0-2.3-0.2-3.4-0.6
                    c-0.5-0.2-0.8-0.8-0.6-1.3s0.8-0.8,1.3-0.6c0.8,0.3,1.7,0.4,2.6,0.4c1.8,0,3-0.5,4.6-1.1C30,6,29.5,7.2,29.5,9
                    C29.5,9.9,29.7,10.8,29.9,11.6L29.9,11.6z M11.6,5.1c0.5-0.2,1.1,0.1,1.3,0.6c0.2,0.5-0.1,1.1-0.6,1.3C11.2,7.3,10,7.5,8.9,7.5
                    l6.4,6.4c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0L7.5,8.9c0,1.1-0.2,2.3-0.6,3.4c-0.2,0.5-0.8,0.8-1.3,0.6
                    c-0.5-0.2-0.8-0.8-0.6-1.3C5.3,10.8,5.5,9.9,5.5,9c0-1.8-0.5-3-1.1-4.6C6,5,7.2,5.5,9,5.5C9.9,5.5,10.8,5.3,11.6,5.1z"/>
            </svg>
        </button>
    );
}

export const ExitFullscreenButton = ({ passClassName, onClickFunction, ariaLabel }) => {

    return (
        <button className={passClassName} onClick={onClickFunction} aria-label={ariaLabel} >
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" >
                <path fillRule="evenodd" clipRule="evenodd" fill="#578270" d="M11.2,26.5l-4.8,4.8c-0.7,0.7-1.9,0.7-2.6,0c-0.7-0.7-0.7-1.9,0-2.6l4.8-4.8c-0.6-0.1-1.2-0.3-1.8-0.5
                    c-0.5-0.2-0.8-0.5-1-1c-0.2-0.4-0.2-1-0.1-1.4c0.2-0.5,0.5-0.8,1-1c0.4-0.2,1-0.2,1.4-0.1c0.7,0.3,1.5,0.4,2.3,0.4
                    c1.4,0,2.3-0.4,3.6-0.8l0.7-0.3c0.7-0.2,1.3,0.4,1.1,1.1L15.5,21c-0.5,1.2-0.8,2.2-0.8,3.6c0,0.8,0.1,1.6,0.4,2.3
                    c0.2,0.5,0.2,1-0.1,1.4c-0.2,0.4-0.6,0.8-1,1c-0.5,0.2-1,0.2-1.4-0.1c-0.4-0.2-0.8-0.6-1-1C11.4,27.7,11.2,27.1,11.2,26.5L11.2,26.5
                    z M8.5,11.2L3.7,6.3C3,5.6,3,4.4,3.7,3.7C4.4,3,5.6,3,6.3,3.7l4.8,4.8c0.1-0.6,0.3-1.2,0.5-1.8c0.2-0.5,0.5-0.8,1-1
                    c0.4-0.2,1-0.2,1.4-0.1c0.5,0.2,0.8,0.5,1,1c0.2,0.4,0.2,1,0.1,1.4c-0.3,0.7-0.4,1.5-0.4,2.3c0,1.4,0.4,2.3,0.8,3.6l0.3,0.7
                    c0.2,0.7-0.4,1.3-1.1,1.1L14,15.5c-1.2-0.5-2.2-0.8-3.6-0.8c-0.8,0-1.6,0.1-2.3,0.4c-0.5,0.2-1,0.2-1.4-0.1c-0.4-0.2-0.8-0.6-1-1
                    c-0.2-0.5-0.2-1,0.1-1.4c0.2-0.4,0.6-0.8,1-1C7.3,11.4,7.9,11.2,8.5,11.2L8.5,11.2z M23.8,8.5l4.8-4.8c0.7-0.7,1.9-0.7,2.6,0
                    c0.7,0.7,0.7,1.9,0,2.6l-4.8,4.8c0.6,0.1,1.2,0.3,1.8,0.5c0.5,0.2,0.8,0.5,1,1c0.2,0.4,0.2,1,0.1,1.4c-0.2,0.5-0.5,0.8-1,1
                    c-0.4,0.2-1,0.2-1.4,0.1c-0.7-0.3-1.5-0.4-2.3-0.4c-1.4,0-2.3,0.4-3.6,0.8l-0.7,0.3c-0.7,0.2-1.3-0.4-1.1-1.1l0.3-0.7
                    c0.5-1.2,0.8-2.2,0.8-3.6c0-0.8-0.1-1.6-0.4-2.3c-0.2-0.5-0.2-1,0.1-1.4c0.2-0.4,0.6-0.8,1-1c0.5-0.2,1-0.2,1.4,0.1
                    c0.4,0.2,0.8,0.6,1,1C23.6,7.3,23.8,7.9,23.8,8.5L23.8,8.5z M26.5,23.8l4.8,4.8c0.7,0.7,0.7,1.9,0,2.6c-0.7,0.7-1.9,0.7-2.6,0
                    l-4.8-4.8c-0.1,0.6-0.3,1.2-0.5,1.8c-0.2,0.5-0.5,0.8-1,1c-0.4,0.2-1,0.2-1.4,0.1c-0.5-0.2-0.8-0.5-1-1c-0.2-0.4-0.2-1-0.1-1.4
                    c0.3-0.7,0.4-1.5,0.4-2.3c0-1.4-0.4-2.3-0.8-3.6l-0.3-0.7c-0.2-0.7,0.4-1.3,1.1-1.1l0.7,0.3c1.2,0.5,2.2,0.8,3.6,0.8
                    c0.8,0,1.6-0.1,2.3-0.4c0.5-0.2,1-0.2,1.4,0.1c0.4,0.2,0.8,0.6,1,1c0.2,0.5,0.2,1-0.1,1.4c-0.2,0.4-0.6,0.8-1,1
                    C27.7,23.6,27.1,23.8,26.5,23.8z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#FBFBFB" d="M14.3,27.2c0.2,0.5-0.1,1.1-0.6,1.3c-0.5,0.2-1.1-0.1-1.3-0.6c-0.4-1.1-0.6-2.2-0.6-3.4l-6.1,6.1
                    c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4l6.1-6.1c-1.1,0-2.3-0.2-3.4-0.6c-0.5-0.2-0.8-0.8-0.6-1.3c0.2-0.5,0.8-0.8,1.3-0.6
                    c0.8,0.3,1.7,0.4,2.6,0.4c1.8,0,3-0.5,4.6-1.1c-0.6,1.6-1.1,2.8-1.1,4.6C13.8,25.6,14,26.5,14.3,27.2L14.3,27.2z M7.8,14.3
                    c-0.5,0.2-1.1-0.1-1.3-0.6c-0.2-0.5,0.1-1.1,0.6-1.3c1.1-0.4,2.2-0.6,3.4-0.6L4.3,5.7c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0
                    l6.1,6.1c0-1.1,0.2-2.3,0.6-3.4c0.2-0.5,0.8-0.8,1.3-0.6c0.5,0.2,0.8,0.8,0.6,1.3c-0.3,0.8-0.4,1.7-0.4,2.6c0,1.8,0.5,3,1.1,4.6
                    c-1.6-0.6-2.8-1.1-4.6-1.1C9.4,13.8,8.5,14,7.8,14.3L7.8,14.3z M20.7,7.8c-0.2-0.5,0.1-1.1,0.6-1.3c0.5-0.2,1.1,0.1,1.3,0.6
                    c0.4,1.1,0.6,2.2,0.6,3.4l6.1-6.1c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4l-6.1,6.1c1.1,0,2.3,0.2,3.4,0.6c0.5,0.2,0.8,0.8,0.6,1.3
                    c-0.2,0.5-0.8,0.8-1.3,0.6c-0.8-0.3-1.7-0.4-2.6-0.4c-1.8,0-3,0.5-4.6,1.1c0.6-1.6,1.1-2.8,1.1-4.6C21.2,9.4,21,8.5,20.7,7.8
                    L20.7,7.8z M27.2,20.7c0.5-0.2,1.1,0.1,1.3,0.6s-0.1,1.1-0.6,1.3c-1.1,0.4-2.2,0.6-3.4,0.6l6.1,6.1c0.4,0.4,0.4,1,0,1.4
                    c-0.4,0.4-1,0.4-1.4,0l-6.1-6.1c0,1.1-0.2,2.3-0.6,3.4c-0.2,0.5-0.8,0.8-1.3,0.6c-0.5-0.2-0.8-0.8-0.6-1.3c0.3-0.8,0.4-1.7,0.4-2.6
                    c0-1.8-0.5-3-1.1-4.6c1.6,0.6,2.8,1.1,4.6,1.1C25.6,21.2,26.5,21,27.2,20.7z"/>
            </svg>
        </button>
    );
}

export const PreviousButton = ({ passClassName, onClickFunction, ariaLabel }) => {

    return (
        <button className={passClassName} onClick={onClickFunction} aria-label={ariaLabel} >
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" >
                <path fillRule="evenodd" clipRule="evenodd" fill="#578270" d="M18.4,17.5c0.6-0.5,1.3-1,1.8-1.6c0.7-0.7,1.3-1.4,1.9-2.2c0.5-0.7,1-1.5,1.4-2.4c0.4-0.9,0-2.1-0.9-2.5
                    c-0.9-0.4-2.1,0-2.5,0.9c-0.3,0.6-0.6,1.2-1,1.8c-0.4,0.6-0.9,1.2-1.5,1.8c-0.8,0.8-1.6,1.5-2.5,2c-0.9,0.6-1.9,1-2.9,1.4
                    c-0.7,0.3-0.7,1.3,0,1.6c1,0.4,2,0.9,2.9,1.4c0.9,0.6,1.7,1.2,2.5,2c0.5,0.5,1,1.1,1.5,1.8c0.4,0.6,0.8,1.2,1,1.8
                    c0.4,0.9,1.5,1.4,2.5,0.9c0.9-0.4,1.4-1.5,0.9-2.5c-0.4-0.8-0.9-1.6-1.4-2.4c-0.6-0.8-1.2-1.5-1.9-2.2C19.7,18.5,19.1,18,18.4,17.5z
                    "/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#FBFBFB" d="M20.9,24.9c0.2,0.5,0.8,0.7,1.3,0.5c0.5-0.2,0.7-0.8,0.5-1.3c-0.7-1.5-1.8-3-3.1-4.4c-0.8-0.8-1.8-1.6-2.7-2.2
                    c1-0.6,1.9-1.4,2.7-2.2c1.3-1.3,2.4-2.9,3.1-4.4c0.2-0.5,0-1.1-0.5-1.3c-0.5-0.2-1.1,0-1.3,0.5c-0.6,1.3-1.5,2.6-2.7,3.8
                    c-1.8,1.9-3.4,2.7-5.6,3.6c2.3,1,3.8,1.8,5.6,3.6C19.4,22.3,20.3,23.6,20.9,24.9z"/>
            </svg>
        </button>
    );
}

export const NextButton = ({ passClassName, onClickFunction, ariaLabel }) => {

    return (
        <button className={passClassName} onClick={onClickFunction} aria-label={ariaLabel} >
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" >
                <path fillRule="evenodd" clipRule="evenodd" fill="#578270" d="M18,17.5c-0.6-0.5-1.3-1-1.8-1.6c-0.7-0.7-1.3-1.4-1.9-2.2c-0.5-0.7-1-1.5-1.4-2.4c-0.4-0.9,0-2.1,0.9-2.5
                    c0.9-0.4,2.1,0,2.5,0.9c0.3,0.6,0.6,1.2,1,1.8c0.4,0.6,0.9,1.2,1.5,1.8c0.8,0.8,1.6,1.5,2.5,2c0.9,0.6,1.9,1,2.9,1.4
                    c0.7,0.3,0.7,1.3,0,1.6c-1,0.4-2,0.9-2.9,1.4c-0.9,0.6-1.7,1.2-2.5,2c-0.5,0.5-1,1.1-1.5,1.8c-0.4,0.6-0.8,1.2-1,1.8
                    c-0.4,0.9-1.5,1.4-2.5,0.9c-0.9-0.4-1.4-1.5-0.9-2.5c0.4-0.8,0.9-1.6,1.4-2.4c0.6-0.8,1.2-1.5,1.9-2.2C16.8,18.5,17.4,18,18,17.5z"
                    />
                <path fillRule="evenodd" clipRule="evenodd" fill="#FBFBFB" d="M15.5,24.9c-0.2,0.5-0.8,0.7-1.3,0.5c-0.5-0.2-0.7-0.8-0.5-1.3c0.7-1.5,1.8-3,3.1-4.4c0.8-0.8,1.8-1.6,2.7-2.2
                    c-1-0.6-1.9-1.4-2.7-2.2c-1.3-1.3-2.4-2.9-3.1-4.4c-0.2-0.5,0-1.1,0.5-1.3c0.5-0.2,1.1,0,1.3,0.5c0.6,1.3,1.5,2.6,2.7,3.8
                    c1.8,1.9,3.4,2.7,5.6,3.6c-2.3,1-3.8,1.8-5.6,3.6C17.1,22.3,16.1,23.6,15.5,24.9z"/>
            </svg>

        </button>
    );
}

export const QuestionButton = ({ passClassName, onClickFunction, ariaLabel }) => {
    return (
        <button className={passClassName} onClick={onClickFunction} aria-label={ariaLabel} >
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" >
                <path fillRule="evenodd" clipRule="evenodd" fill="#FBFBFB" d="M18.5,9.2c1.7,0,3.1,0.3,4.1,0.8c1,0.5,1.5,1.2,1.5,2.2c0,0.6-0.2,1.2-0.5,1.6c-0.3,0.5-0.8,0.9-1.2,1.3
                    c-0.5,0.4-1,0.8-1.5,1.2c-0.5,0.4-0.9,0.8-1.3,1.3c-0.3,0.5-0.5,1-0.5,1.7c0,0.2,0,0.5,0.1,0.8c0.1,0.3,0.2,0.6,0.3,0.9
                    c-0.2,0.1-0.5,0.2-0.8,0.2c-0.3,0-0.6,0.1-0.9,0.1c-0.4-0.2-0.8-0.6-1.2-1.1c-0.4-0.5-0.5-1.1-0.5-1.8c0-0.6,0.1-1.1,0.4-1.6
                    c0.3-0.4,0.6-0.8,1-1.1c0.4-0.3,0.8-0.7,1.2-1c0.4-0.3,0.7-0.6,1-1c0.3-0.3,0.4-0.7,0.4-1.2c0-0.6-0.2-1.1-0.7-1.5
                    c-0.5-0.4-1-0.5-1.6-0.5c-0.5,0-0.9,0.1-1.2,0.3c-0.3,0.2-0.6,0.5-0.8,0.9c-0.2,0.4-0.4,1-0.5,1.8c-0.4,0-0.9,0-1.3-0.1
                    c-0.4-0.1-0.8-0.1-1.1-0.2c0.1-0.5,0.1-1,0.1-1.5c0-0.5,0-0.9-0.1-1.4c0.6-0.3,1.4-0.5,2.3-0.7C16.1,9.3,17.2,9.2,18.5,9.2z
                    M18.5,25.8c-0.7,0-1.2-0.1-1.6-0.4c-0.4-0.3-0.6-0.6-0.6-1.1c0-0.4,0.2-0.8,0.7-1.1c0.4-0.3,1-0.4,1.6-0.4s1.2,0.2,1.5,0.5
                    c0.4,0.3,0.6,0.6,0.6,1c0,0.5-0.2,0.9-0.6,1.1C19.7,25.7,19.1,25.8,18.5,25.8z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#578270" d="M18.5,26.1c-0.7,0-1.2-0.2-1.6-0.5c-0.4-0.3-0.7-0.8-0.7-1.3c0-0.5,0.3-1,0.8-1.3c0.5-0.3,1-0.5,1.7-0.5
                    c0.7,0,1.2,0.2,1.6,0.5c0.4,0.4,0.6,0.8,0.6,1.2c0,0.6-0.3,1.1-0.7,1.4C19.7,26,19.1,26.1,18.5,26.1z M18.6,23.3
                    c-0.5,0-1,0.1-1.3,0.4c-0.3,0.2-0.5,0.5-0.5,0.8c0,0.3,0.1,0.6,0.4,0.7c0.3,0.2,0.7,0.3,1.2,0.3c0.5,0,0.9-0.1,1.3-0.3
                    c0.3-0.2,0.4-0.5,0.4-0.8c0-0.2-0.1-0.4-0.4-0.7C19.5,23.4,19.1,23.3,18.6,23.3z M17.8,21.6l-0.1,0c-0.5-0.2-0.9-0.6-1.3-1.2
                    c-0.4-0.6-0.6-1.3-0.6-2c0-0.7,0.2-1.3,0.5-1.8c0.3-0.5,0.7-0.9,1.1-1.2c0.4-0.3,0.8-0.7,1.2-1c0.4-0.3,0.7-0.6,0.9-0.9
                    c0.2-0.3,0.3-0.6,0.3-1c0-0.5-0.2-0.9-0.6-1.2c-0.4-0.3-0.9-0.5-1.4-0.5c-0.4,0-0.8,0.1-1.1,0.2c-0.3,0.2-0.5,0.4-0.7,0.8
                    c-0.2,0.4-0.4,1-0.5,1.7l-0.1,0.3h-0.3c-0.5,0-0.9,0-1.3-0.1c-0.4-0.1-0.8-0.1-1.2-0.3l-0.3-0.1l0-0.3c0.1-0.5,0.1-1,0.1-1.5
                    c0-0.4,0-0.9-0.1-1.3L12.5,10l0.3-0.1c0.6-0.3,1.4-0.5,2.4-0.7C16,9,17.2,8.9,18.5,8.9c1.8,0,3.2,0.3,4.3,0.8
                    c1.1,0.6,1.7,1.4,1.7,2.5c0,0.7-0.2,1.3-0.6,1.9c-0.4,0.5-0.8,1-1.3,1.4c-0.5,0.4-1,0.8-1.5,1.2c-0.5,0.4-0.9,0.8-1.2,1.2
                    c-0.3,0.4-0.4,0.9-0.4,1.4c0,0.2,0,0.5,0.1,0.7c0.1,0.3,0.2,0.6,0.3,0.8l0.2,0.4l-0.3,0.1c-0.3,0.1-0.6,0.2-0.9,0.2
                    C18.5,21.5,18.1,21.6,17.8,21.6z M17.8,10.1c0.7,0,1.3,0.2,1.8,0.6c0.6,0.4,0.8,1,0.8,1.8c0,0.6-0.2,1-0.5,1.4
                    c-0.3,0.4-0.6,0.7-1,1c-0.4,0.3-0.8,0.6-1.2,1c-0.4,0.3-0.7,0.7-0.9,1.1c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.6,0.2,1.1,0.5,1.6
                    c0.3,0.5,0.6,0.8,1,1c0.3,0,0.6,0,0.8-0.1c0.2,0,0.3,0,0.4-0.1c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.3-0.1-0.7-0.1-0.9
                    c0-0.7,0.2-1.3,0.6-1.9c0.4-0.5,0.8-1,1.3-1.4c0.5-0.4,1-0.8,1.5-1.2c0.5-0.4,0.9-0.8,1.2-1.2c0.3-0.4,0.4-0.9,0.4-1.4
                    c0-0.8-0.4-1.4-1.3-1.9c-1-0.5-2.3-0.7-4-0.7c-1.3,0-2.4,0.1-3.3,0.3c-0.8,0.2-1.5,0.4-2,0.6c0.1,0.4,0.1,0.8,0.1,1.2
                    c0,0.4,0,0.8-0.1,1.2c0.2,0.1,0.5,0.1,0.8,0.2c0.3,0,0.7,0.1,1,0.1c0.1-0.7,0.3-1.2,0.5-1.6c0.3-0.5,0.6-0.9,1-1.1
                    C16.9,10.2,17.3,10.1,17.8,10.1z"/>
            </svg>

        </button>
    );
}

export const ZoomButton = ({ passClassName, onClickFunction, ariaLabel }) => {
    return (
        <button className={passClassName} onClick={onClickFunction} aria-label={ariaLabel} >
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" >
                <g>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#FBFBFB" d="M22,25.5c-0.2,0-0.7-0.7-0.7-1c0-0.4,0.4-0.5,0.5-0.8c0.1-0.5-0.5-1-0.7-1.4c-0.6-0.8-1.2-2.1-2.2-1.9
                        c-0.9,0.1-1.7,0.4-2.6,0.3c-1-0.1-2-0.5-2.8-1.2c-1.5-1.2-2.3-3.3-1.8-5.2c1-3.9,6.5-6.3,9.8-3.7c1.6,1.3,2.4,3.4,1.9,5.4
                        c-0.1,0.5-0.3,0.9-0.5,1.3c-0.2,0.3-0.7,0.7-0.7,1c-0.1,0.5,0.3,0.9,0.6,1.3c0.3,0.4,0.6,0.9,1,1.3c0.2,0.2,0.4,0.6,0.7,0.7
                        c0.5,0.2,0.7-0.4,1.3,0c1.3,0.9-0.3,1.5-1.1,2.1c-0.5,0.3-0.9,0.6-1.4,1C22.9,24.9,22.3,25.5,22,25.5z M17.2,12
                        c-1.6,0-3.3,1.4-3.2,3.1c0.2,1.8,2,3.3,3.7,3.2c1.6-0.2,3-1.4,2.9-3.1C20.6,13.4,19,12,17.2,12z"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#578270" d="M18.1,9.9c0.8,0,1.5,0.2,2.1,0.5c0.8,0.4,1.5,1,2,1.8c0.4,0.6,0.7,1.3,0.8,1.9c0.1,0.7,0.1,1.3-0.1,2
                        c-0.2,0.6-0.5,1.3-1,1.8c0,0-0.2,0.2-0.3,0.4c-0.1,0.2-0.1,0.4,0,0.6c0.1,0.2,0.2,0.5,0.5,0.8l1.2,1.6c0.2,0.3,0.5,0.6,0.6,0.7
                        c0.2,0.1,0.3,0.2,0.5,0.2c0,0,0.1,0,0.1,0c0.2,0,0.4-0.1,0.7-0.3c0.1,0.1,0.2,0.1,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.4
                        c-0.3,0.2-0.6,0.3-0.9,0.6c-0.3,0.2-0.6,0.4-1,0.7c-0.3,0.2-0.7,0.5-1,0.7c-0.3,0.2-0.6,0.5-0.9,0.7c-0.1-0.1-0.2-0.3-0.3-0.4
                        c-0.1-0.1-0.1-0.3-0.2-0.4c0.2-0.2,0.4-0.4,0.5-0.5c0.1-0.2,0.1-0.4,0-0.6c-0.1-0.2-0.2-0.5-0.5-0.8l-1.2-1.6
                        c-0.3-0.3-0.5-0.6-0.6-0.7C19.5,20,19.4,20,19.2,20c0,0-0.1,0-0.1,0c-0.2,0-0.4,0-0.7,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                        c-0.4,0.1-0.9,0.2-1.3,0.2c-0.1,0-0.2,0-0.4,0c-0.8,0-1.6-0.2-2.3-0.5c-0.8-0.4-1.5-1-2-1.9c-0.4-0.6-0.6-1.3-0.7-2
                        c-0.1-0.7,0-1.3,0.2-2c0.2-0.6,0.5-1.2,1-1.8c0.5-0.6,1-1,1.7-1.4c1-0.6,1.9-0.9,2.9-1C17.7,9.9,17.9,9.9,18.1,9.9 M17.5,18.6
                        c0.1,0,0.3,0,0.5,0c0.5-0.1,1-0.3,1.6-0.6c0.6-0.3,1-0.8,1.2-1.3c0.3-0.5,0.4-1,0.3-1.6c0-0.6-0.2-1.1-0.6-1.6
                        c-0.4-0.6-0.8-1.1-1.4-1.4c-0.6-0.3-1.2-0.5-1.8-0.5c0,0,0,0,0,0c-0.7,0-1.3,0.2-2,0.6c-0.5,0.3-0.9,0.7-1.2,1.2
                        c-0.3,0.5-0.4,1-0.4,1.5c0,0.5,0.2,1.1,0.6,1.7c0.3,0.4,0.6,0.8,1,1.1c0.4,0.3,0.8,0.5,1.3,0.7C16.8,18.5,17.1,18.6,17.5,18.6
                        M18.1,9.2L18.1,9.2c-0.2,0-0.4,0-0.6,0c-1.1,0.1-2.1,0.5-3.2,1.1c-0.8,0.4-1.4,1-1.9,1.6c-0.5,0.6-0.9,1.3-1.1,2
                        c-0.2,0.7-0.3,1.5-0.2,2.2c0.1,0.8,0.4,1.5,0.8,2.2c0.6,0.9,1.4,1.6,2.3,2.1c0.8,0.4,1.7,0.6,2.6,0.6c0.1,0,0.3,0,0.4,0
                        c0.4,0,0.9-0.1,1.4-0.2c0,0,0.1,0,0.1,0c0.2-0.1,0.3-0.1,0.5-0.1l0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0.1,0.2,0.2,0.5,0.6
                        l1.2,1.6c0.3,0.4,0.4,0.6,0.4,0.7c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.3,0.3c-0.2,0.2-0.3,0.5-0.2,0.7c0.1,0.1,0.1,0.3,0.2,0.5
                        c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.1,0.3,0.2,0.4,0.3c0,0,0.1,0,0.1,0c0.1,0,0.3-0.1,0.4-0.1c0.2-0.2,0.5-0.4,0.9-0.7
                        c0.3-0.2,0.7-0.5,1-0.7c0.3-0.2,0.6-0.4,1-0.7c0.3-0.2,0.6-0.4,0.8-0.5c0.2-0.1,0.3-0.3,0.3-0.4c0-0.2,0-0.4-0.1-0.5
                        c-0.1-0.2-0.2-0.3-0.4-0.4c-0.1-0.2-0.3-0.3-0.4-0.4c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0.1c-0.2,0.1-0.4,0.2-0.4,0.2
                        c0,0,0,0,0,0c0,0,0,0-0.1,0c-0.1-0.1-0.3-0.2-0.5-0.6l-1.2-1.6c-0.3-0.4-0.4-0.6-0.4-0.7c0,0,0,0,0,0c0,0,0-0.1,0.2-0.2
                        c0,0,0,0,0,0c0.5-0.6,0.9-1.3,1.1-2.1c0.2-0.7,0.3-1.5,0.2-2.3c-0.1-0.8-0.4-1.5-0.8-2.2c-0.6-0.9-1.4-1.6-2.3-2.1
                        C19.7,9.4,18.9,9.2,18.1,9.2L18.1,9.2z M17.5,17.9c-0.3,0-0.6,0-0.8-0.1c-0.4-0.1-0.7-0.3-1-0.6c-0.3-0.3-0.6-0.6-0.8-1
                        c-0.3-0.5-0.4-0.9-0.5-1.4c0-0.4,0.1-0.8,0.3-1.2c0.2-0.4,0.5-0.7,1-0.9c0.5-0.3,1.1-0.5,1.6-0.5c0,0,0,0,0,0
                        c0.6,0,1.1,0.1,1.5,0.4c0.5,0.3,0.9,0.6,1.2,1.1c0.3,0.5,0.4,0.9,0.5,1.4c0,0.4,0,0.8-0.2,1.2c-0.2,0.4-0.5,0.7-1,1
                        c-0.4,0.3-0.9,0.4-1.3,0.5C17.7,17.9,17.6,17.9,17.5,17.9L17.5,17.9z"/>
                </g>
            </svg>
        </button>
    );
}

